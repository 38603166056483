const MBLogo = () => {
  return (
    <>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="204.258px" height="24.029px" viewBox="0 0 204.258 24.029" enableBackground="new 0 0 204.258 24.029">
      <path fill="#FFFFFF" d="M4.302,0.114h5.995l1.372,12.419l6.991-12.419h6.022L20.377,19h-4.783L17.77,9.45l0.727-2.469L17.233,9.45
        L11.587,19H8.766L7.42,9.45V7.035L4.76,19H0L4.302,0.114z"/>
      <path fill="#FFFFFF" d="M49.313,9.54c-0.196-0.389-0.491-0.701-0.886-0.939c-0.396-0.24-0.823-0.36-1.289-0.36
        c-0.522,0-1.037,0.132-1.549,0.388c-0.51,0.257-0.967,0.596-1.37,1.022c-0.403,0.425-0.726,0.918-0.967,1.475
        c-0.243,0.559-0.364,1.132-0.364,1.714c0,0.814,0.228,1.504,0.687,2.072c0.457,0.564,1.071,0.848,1.841,0.848
        c0.467,0,0.905-0.082,1.318-0.248c0.411-0.168,0.86-0.467,1.343-0.889l1.963,2.242c-0.826,0.664-1.74,1.195-2.744,1.588
        c-1.003,0.395-2.033,0.59-3.09,0.59c-0.878,0-1.677-0.145-2.393-0.436c-0.717-0.289-1.331-0.686-1.843-1.188
        c-0.511-0.504-0.911-1.096-1.196-1.785c-0.287-0.688-0.431-1.436-0.431-2.246c0-1.126,0.261-2.203,0.779-3.221
        c0.52-1.023,1.207-1.919,2.059-2.685c0.848-0.766,1.822-1.376,2.915-1.836c1.093-0.456,2.224-0.687,3.388-0.687
        c1.076,0,2.021,0.201,2.838,0.603c0.815,0.404,1.48,0.947,2,1.629L49.313,9.54z"/>
      <path fill="#FFFFFF" d="M55.155,0h4.49l-1.414,6.203c0.558-0.43,1.168-0.767,1.842-1.007c0.672-0.24,1.321-0.361,1.95-0.361
        c1.497,0,2.591,0.381,3.287,1.139c0.693,0.761,1.041,1.749,1.041,2.966c0,0.305-0.014,0.613-0.042,0.925
        c-0.026,0.312-0.085,0.642-0.177,0.98L64.268,19h-4.502l2.042-8.907c0.016-0.107,0.031-0.211,0.04-0.309
        c0.008-0.099,0.013-0.192,0.013-0.283c0-0.411-0.126-0.736-0.377-0.979c-0.251-0.239-0.635-0.359-1.155-0.359
        c-0.306,0-0.619,0.052-0.941,0.159c-0.324,0.109-0.63,0.259-0.915,0.457c-0.289,0.196-0.538,0.436-0.751,0.711
        c-0.217,0.276-0.358,0.576-0.431,0.898L55.327,19h-4.49L55.155,0z"/>
      <path fill="#FFFFFF" d="M67.157,12.99c0.105-1.109,0.402-2.154,0.886-3.138c0.485-0.983,1.098-1.849,1.844-2.591
        c0.74-0.741,1.566-1.325,2.472-1.756c0.903-0.429,1.832-0.644,2.781-0.644c1.417,0,2.638,0.536,3.657,1.609l0.35-1.503h4.488
        L80.311,19h-4.492c0,0-4.774,0.279-5.787-0.297c-0.565-0.318-1.102-0.643-1.53-1.123c-0.433-0.484-0.77-1.057-1.011-1.719
        c-0.241-0.662-0.362-1.395-0.362-2.201c0-0.105,0-0.217,0-0.334C67.128,13.21,67.138,13.097,67.157,12.99z M71.807,12.614
        c-0.018,0.088-0.027,0.223-0.027,0.401c0,0.752,0.178,1.402,0.539,1.947c0.356,0.545,0.912,0.818,1.665,0.818
        c0.484,0,0.949-0.131,1.398-0.391c0.447-0.26,0.847-0.611,1.197-1.059s0.627-0.955,0.834-1.527c0.204-0.573,0.308-1.174,0.308-1.798
        c0-0.789-0.175-1.453-0.523-2c-0.35-0.546-0.841-0.817-1.466-0.817c-0.52,0-1.008,0.129-1.466,0.39
        c-0.457,0.257-0.86,0.593-1.21,1.006c-0.35,0.411-0.628,0.886-0.845,1.422C71.995,11.54,71.861,12.078,71.807,12.614z"/>
      <path fill="#FFFFFF" d="M85.653,4.968h4.167l-0.134,1.396c0.698-0.536,1.472-0.928,2.326-1.168c0.851-0.24,1.634-0.361,2.352-0.361
        c0.771,0,1.424,0.097,1.962,0.294c0.539,0.196,0.981,0.479,1.329,0.845c0.351,0.368,0.605,0.797,0.77,1.287
        c0.159,0.493,0.24,1.035,0.24,1.624c0,0.644-0.081,1.298-0.24,1.96L96.566,19h-4.464l1.855-8.236
        c0.071-0.304,0.107-0.628,0.107-0.968c0-0.481-0.11-0.875-0.334-1.181c-0.225-0.303-0.614-0.453-1.171-0.453
        c-0.338,0-0.703,0.052-1.089,0.159c-0.385,0.109-0.751,0.259-1.101,0.457c-0.349,0.196-0.659,0.439-0.927,0.723
        c-0.271,0.288-0.458,0.609-0.565,0.969L86.941,19h-4.488L85.653,4.968z"/>
      <path fill="#FFFFFF" d="M101.951,4.968h4.533L103.287,19h-4.489L101.951,4.968z M103.104,0h4.518l-0.652,2.794h-4.515L103.104,0z"/>
      <path fill="#F39519" d="M125.217,0.114h10.349c0.628,0,1.206,0.099,1.734,0.294c0.527,0.196,0.983,0.475,1.366,0.832
        c0.381,0.356,0.68,0.781,0.887,1.273c0.209,0.492,0.315,1.031,0.315,1.624c0,0.465-0.069,0.934-0.206,1.406
        c-0.137,0.475-0.321,0.936-0.559,1.382c-0.239,0.449-0.52,0.869-0.845,1.263c-0.328,0.392-0.676,0.74-1.052,1.045
        c0.356,0.358,0.62,0.819,0.791,1.381c0.17,0.565,0.257,1.16,0.257,1.786c0,0.931-0.198,1.796-0.591,2.601
        c-0.395,0.805-0.915,1.504-1.56,2.094c-0.646,0.59-1.385,1.055-2.22,1.395c-0.831,0.342-1.696,0.51-2.593,0.51h-10.379
        L125.217,0.114z M130.888,15.056c0.323,0,0.638-0.072,0.942-0.217c0.305-0.143,0.582-0.328,0.832-0.561
        c0.251-0.232,0.453-0.5,0.606-0.807c0.15-0.303,0.227-0.615,0.227-0.939c0-0.464-0.152-0.861-0.457-1.194
        c-0.305-0.33-0.698-0.494-1.182-0.494h-4.354l-0.97,4.211H130.888z M132.502,7.73c0.27,0,0.536-0.061,0.805-0.188
        c0.271-0.122,0.513-0.284,0.728-0.482c0.214-0.196,0.391-0.43,0.525-0.696c0.133-0.27,0.202-0.556,0.202-0.859
        c0-0.411-0.136-0.754-0.405-1.032c-0.269-0.278-0.618-0.416-1.05-0.416h-4.274L128.2,7.73H132.502z"/>
      <path fill="#F39519" d="M168.698,0l-1.301,5.437c-0.449-0.244-0.921-0.422-1.411-0.536c-0.496-0.114-1.002-0.171-1.52-0.171
        c-1.093,0-2.166,0.231-3.215,0.694c-1.049,0.464-1.979,1.09-2.795,1.877c-0.815,0.788-1.476,1.691-1.975,2.718
        c-0.504,1.023-0.752,2.094-0.752,3.213c0,0.805,0.128,1.539,0.387,2.205c0.261,0.664,0.619,1.238,1.076,1.717
        c0.458,0.484,0.999,0.857,1.626,1.129c0.311,0.135,0.635,0.234,0.975,0.303h-0.002l0.01,0.002c0.004,0,0.005,0.002,0.008,0.002
        c0.243,0.049,2.127,0.529,4.479,0.383c3.046-0.188,4.433-1.068,4.433-1.068L173.186,0H168.698z M160.271,12.455
        c0-0.069,0.01-0.139,0.03-0.21c0.052-0.625,0.212-1.201,0.48-1.725c0.271-0.523,0.611-0.972,1.023-1.348
        c0.413-0.374,0.89-0.664,1.439-0.877c0.546-0.207,1.123-0.312,1.732-0.312c0.303,0,0.607,0.034,0.914,0.104
        c0.305,0.069,0.593,0.167,0.86,0.288l-1.584,6.616c-0.289,0.121-0.594,0.225-0.915,0.314c-0.324,0.086-0.664,0.131-1.023,0.131
        c-0.933,0-1.657-0.254-2.177-0.76s-0.781-1.176-0.781-2.012C160.271,12.596,160.271,12.524,160.271,12.455z"/>
      <path fill="#F39519" d="M155.73,4.96h-4.464l-1.75,8.118c-0.106,0.32-0.276,0.623-0.509,0.896c-0.233,0.279-0.506,0.516-0.82,0.715
        c-0.312,0.193-0.646,0.352-0.995,0.467c-0.349,0.117-0.683,0.172-1.007,0.172c-0.521,0-0.955-0.146-1.306-0.439
        c-0.349-0.297-0.522-0.746-0.522-1.355c0-0.27,0.036-0.535,0.106-0.805l1.641-7.768h-4.488l-1.693,8.038
        c-0.107,0.463-0.162,0.912-0.162,1.34c0,0.664,0.116,1.262,0.349,1.797c0.188,0.438,0.443,0.822,0.752,1.158
        c-0.008,0-0.014,0-0.022,0c0.039,0.037,0.082,0.07,0.125,0.105c0.037,0.039,0.072,0.082,0.112,0.117
        c0.026,0.025,0.059,0.045,0.084,0.07c0.006-0.002,0.011-0.006,0.015-0.006c0.354,0.275,0.744,0.506,1.177,0.688
        c0.322,0.137,0.662,0.24,1.019,0.309h-0.001c0.003,0,0.009,0,0.011,0.002c0.003,0,0.007,0.002,0.01,0.002
        c0.253,0.051,2.228,0.539,4.692,0.391c3.197-0.193,4.65-1.09,4.65-1.09l1.479-6.03c-0.01,0.005-0.02,0.011-0.03,0.015L155.73,4.96z"
        />
      <path fill="#F39519" d="M184.911,0l-1.278,5.437c-0.445-0.244-0.918-0.422-1.409-0.536c-0.495-0.114-1.002-0.171-1.521-0.171
        c-1.094,0-2.163,0.231-3.21,0.694c-1.048,0.464-1.983,1.09-2.799,1.877c-0.817,0.788-1.473,1.691-1.976,2.718
        c-0.5,1.023-0.753,2.094-0.753,3.213c0,0.805,0.132,1.539,0.39,2.205c0.261,0.664,0.619,1.238,1.078,1.717
        c0.454,0.484,0.996,0.857,1.627,1.129c0.307,0.135,0.631,0.234,0.971,0.303h-0.001c0.003,0,0.008,0.002,0.008,0.002
        c0.006,0,0.009,0.002,0.009,0.002c0.242,0.049,2.129,0.529,4.477,0.383c3.052-0.188,4.436-1.068,4.436-1.068L189.4,0H184.911z
        M176.511,12.455c0-0.069,0.008-0.139,0.024-0.21c0.056-0.625,0.218-1.201,0.485-1.725c0.27-0.523,0.61-0.972,1.021-1.348
        c0.412-0.374,0.892-0.664,1.439-0.877c0.547-0.207,1.125-0.312,1.734-0.312c0.306,0,0.609,0.034,0.914,0.104
        c0.304,0.069,0.591,0.167,0.857,0.288l-1.585,6.616c-0.287,0.121-0.591,0.225-0.913,0.314c-0.323,0.086-0.665,0.131-1.022,0.131
        c-0.932,0-1.659-0.254-2.177-0.76c-0.52-0.506-0.779-1.176-0.779-2.012C176.511,12.596,176.511,12.524,176.511,12.455z"/>
      <path fill="#F39519" d="M199.77,4.858l-3.784,6.976l-1.62,2.609l-0.392-3.859l-0.497-5.726h-4.461l2.26,14.598
        c-0.147,0.305-0.341,0.609-0.594,0.916c-0.252,0.303-0.556,0.457-0.915,0.457c-0.196,0-0.387-0.119-0.577-0.35
        c-0.188-0.232-0.301-0.432-0.336-0.594l-3.307,1.264c0.179,0.861,0.581,1.555,1.21,2.082c0.628,0.531,1.426,0.797,2.393,0.797
        c1.092,0,2.169-0.369,3.227-1.102c1.057-0.736,2.032-1.838,2.93-3.309l8.952-14.76H199.77z"/>
      <path fill="#FFFFFF" d="M117.295,9.54c-0.197-0.389-0.494-0.701-0.89-0.939c-0.391-0.24-0.822-0.36-1.289-0.36
        c-0.521,0-1.035,0.132-1.547,0.388c-0.511,0.257-0.965,0.596-1.368,1.022c-0.404,0.425-0.728,0.918-0.97,1.475
        c-0.241,0.559-0.361,1.132-0.361,1.714c0,0.814,0.228,1.504,0.687,2.072c0.454,0.564,1.068,0.848,1.84,0.848
        c0.465,0,0.906-0.082,1.317-0.248c0.411-0.168,0.861-0.467,1.344-0.889l1.962,2.242c-0.823,0.664-1.738,1.195-2.739,1.588
        c-1.006,0.395-2.037,0.59-3.096,0.59c-0.876,0-1.674-0.145-2.391-0.436c-0.716-0.289-1.331-0.686-1.842-1.188
        c-0.51-0.504-0.91-1.096-1.196-1.785c-0.287-0.688-0.43-1.436-0.43-2.246c0-1.126,0.258-2.203,0.779-3.221
        c0.52-1.023,1.205-1.919,2.056-2.685c0.853-0.766,1.824-1.376,2.918-1.836c1.092-0.456,2.224-0.687,3.388-0.687
        c1.075,0,2.02,0.201,2.835,0.603c0.815,0.404,1.484,0.947,2.004,1.629L117.295,9.54z"/>
      <path fill="#FFFFFF" d="M27.639,12.929c-0.021,0.104-0.033,0.207-0.042,0.311c-0.008,0.104-0.013,0.199-0.013,0.285
        c0,0.707,0.25,1.225,0.659,1.635c2.158,2.166,6.114-0.025,7.154-0.873l1.801,1.867c-0.86,0.779-1.832,1.348-2.836,1.855
        c-3.076,1.551-7.585,1.266-9.386-0.752c-0.464-0.52-0.855-1.086-1.118-1.754c-0.257-0.668-0.387-1.391-0.387-2.172
        c0-1.211,0.267-2.324,0.806-3.337c0.536-1.015,1.24-1.894,2.109-2.64c0.869-0.745,1.851-1.325,2.944-1.74
        c1.092-0.418,2.196-0.625,3.307-0.625c0.967,0,1.804,0.1,2.514,0.3c0.706,0.199,1.291,0.469,1.748,0.806
        c0.457,0.339,0.792,0.728,1.006,1.17c0.217,0.443,0.326,0.906,0.326,1.395c0,0.521-0.156,1.066-0.458,1.639
        c-0.305,0.573-0.772,1.097-1.398,1.575c-0.628,0.476-1.417,0.869-2.366,1.171c-0.949,0.305-2.08,0.455-3.387,0.455
        c-0.522,0-1.004-0.043-1.451-0.129C28.722,13.281,28.212,13.136,27.639,12.929z M32.314,8.205c-0.413,0-0.83,0.057-1.249,0.17
        c-0.421,0.112-0.819,0.271-1.197,0.48c-0.375,0.205-0.712,0.463-1.007,0.766c-0.296,0.304-0.517,0.651-0.661,1.052
        c0.359,0.138,0.795,0.244,1.306,0.322c0.51,0.078,1.051,0.117,1.627,0.117c0.429,0,0.837-0.035,1.221-0.104
        c0.385-0.068,0.728-0.178,1.022-0.325c0.296-0.147,0.536-0.324,0.726-0.531c0.188-0.207,0.301-0.45,0.336-0.728
        c0-0.346-0.189-0.637-0.565-0.868C33.499,8.321,32.979,8.205,32.314,8.205z"/>
    </svg>
    </>
  )
}

export default MBLogo;
