import { useState } from 'react';

import Image from 'next/image';

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  ButtonBase,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  useMediaQuery
} from '@mui/material';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';

import useAuth from '../src/auth';

import MBLogo from './MBLogo';
import Notifications from './Notifications';
import { NextLinkComposed } from './Link';


const TopNav = ({ pageTitle }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { logout, user } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorNotifications, setAnchorNotifications] = useState(null);
  const notificationsMenuOpen = Boolean(anchorNotifications);

  const openNotifications = (event) => {
    setAnchorNotifications(event.currentTarget);
  };

  const closeNotifications = () => {
    setAnchorNotifications(null);
  };

  return (
    <AppBar position="static" sx={{ flexGrow: 1 }}>
      <Toolbar>
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <ButtonBase
            component={NextLinkComposed}
            to={{ pathname: `/` }}
            sx={{
              height: isMobile ? 56 : 64,
              px: 2,
              ml: -2
            }}
          >
            {isMobile ? <Image
              src="/dash/img/logos/logo-72x72.png"
              width={56}
              height={56}
              quality={100}
              alt="MB"
            /> : <MBLogo />}
          </ButtonBase>
          {pageTitle &&
            <Box
              sx={{
                color: 'white',
                fontSize: 'h6.fontSize',
                display: {
                  xs: 'block',
                  md: 'none'
                }
              }}
            >{pageTitle}</Box>
          }
          {user &&
            <Box>
              <IconButton
                color="inherit"
                onClick={openNotifications}
                size="large"
              >
                <Badge badgeContent={user.unread_notifications} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>

              <Menu
                anchorEl={anchorNotifications}
                open={notificationsMenuOpen}
                onClose={closeNotifications}
                onClick={closeNotifications}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    // mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: isMobile ? 68 : 18,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                    py: 0
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{

                }}
                MenuListProps={{
                  sx: {
                    py: 0
                  }
                }}
              >
                <Notifications closeNotifications={closeNotifications} />
              </Menu>

              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                >
                  <Avatar
                    sx={{ bgcolor: 'primary.light' }}
                    alt={user.first_name}
                    src={user.picture}
                  >{user.initials}</Avatar>
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem
                  sx={{
                    minWidth: 200
                  }}
                  component={NextLinkComposed}
                  to={{ pathname: '/profile/' }}
                >
                  <Avatar
                    sx={{ bgcolor: 'primary.light' }}
                    alt={user.first_name}
                    src={user.picture}
                  >{user.initials}</Avatar> <span>{user.first_name} {user.last_name}</span>
                </MenuItem>

                {user.is_business &&
                  <MenuItem
                    component="a"
                    href="/hub/"
                  >
                    <Avatar sx={{ bgcolor: 'primary.light' }}>
                      <AutorenewIcon />
                    </Avatar> Switch to Business
                  </MenuItem>
                }
                <Divider />

                <MenuItem
                  component={NextLinkComposed}
                  to={{ pathname: '/' }}
                >
                  <ListItemIcon>
                    <DirectionsCarIcon fontSize="small" />
                  </ListItemIcon>
                  My Cars
                </MenuItem>
                <MenuItem
                  component={NextLinkComposed}
                  to={{ pathname: '/profile/' }}
                >
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  My Profile
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          }
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopNav;
